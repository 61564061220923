import {
  Alert,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
  notification,
} from "antd";
import { ProfilePictureUpload } from "../profile-picture-upload/ProfilePictureUpload";
import "./ProfileSettingsForm.scss";
import { PhoneNumber, SubmitButton } from "shared/components";
import { useBoundStore } from "store";
import { useUpdateProfileSettings } from "users/hooks";
import { isEmpty } from "lodash";
import { useEffect } from "react";

const getPhoneNumberObject = (inputPhoneNumber?: string) => {
  if (isEmpty(inputPhoneNumber))
    return {
      countryCode: "",
      areaCode: "",
      phoneNumberOne: "",
      phoneNumberTwo: "",
    };

  if (inputPhoneNumber?.length === 12) {
    return {
      countryCode: inputPhoneNumber.slice(0, 2),
      areaCode: inputPhoneNumber.slice(2, 5),
      phoneNumberOne: inputPhoneNumber.slice(5, 8),
      phoneNumberTwo: inputPhoneNumber.slice(8),
    };
  } else if (inputPhoneNumber?.length === 11) {
    return {
      countryCode: inputPhoneNumber.slice(0, 1),
      areaCode: inputPhoneNumber.slice(1, 4),
      phoneNumberOne: inputPhoneNumber.slice(4, 7),
      phoneNumberTwo: inputPhoneNumber.slice(7),
    };
  } else {
    return {
      countryCode: "",
      areaCode: inputPhoneNumber?.slice(0, 3),
      phoneNumberOne: inputPhoneNumber?.slice(3, 6),
      phoneNumberTwo: inputPhoneNumber?.slice(6),
    };
  }
};

export const ProfileSettingsForm = () => {
  const [form] = Form.useForm();
  const user = useBoundStore((state) => state.user);
  const phone = getPhoneNumberObject(user?.phoneNumber);
  const { updateProfileSettings, error, success } = useUpdateProfileSettings();
  const [api, contextHolder] = notification.useNotification();

  const onFinishHandler = async (values: any) => {
    const email = values?.email?.trim();
    const firstName = values?.firstName?.trim();
    const lastName = values?.lastName?.trim();
    const phoneNumber = `+1${values?.phoneNumber?.trim()}`;
    const company = values?.company?.trim();
    const service = values?.service?.trim();

    await updateProfileSettings({
      email,
      firstName,
      lastName,
      phoneNumber,
      company,
      service,
    });

    form.resetFields();
  };

  useEffect(() => {
    if (success) {
      api.success({
        duration: 3,
        message: "Update profile information complete",
        description: "Your profile information has been updated.",
      });
    }
  }, [success, api]);

  return (
    <>
      {contextHolder}
      <Row>
        <Col>
          <Typography.Title className='profile-settings-title' level={3}>
            Your profile picture
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <ProfilePictureUpload />
        </Col>
      </Row>
      <Row>
        <Col>
          <Typography.Title className='profile-settings-title' level={3}>
            Your profile information
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col>
          {error && (
            <Alert
              data-testid='profile-settings-error'
              className='profile-settings-error'
              message='Error'
              description={error?.message}
              type='error'
              showIcon
            />
          )}
          <Form
            form={form}
            name='profile-settings-form'
            data-testid='profile-settings-form'
            layout='vertical'
            onFinish={onFinishHandler}
            requiredMark={false}
            initialValues={{
              ...user,
              areaCode: phone.areaCode,
              phoneNumberOne: phone.phoneNumberOne,
              phoneNumberTwo: phone.phoneNumberTwo,
            }}
          >
            <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
              <Col span={12}>
                <Form.Item
                  name='firstName'
                  data-testid='firstName'
                  label='First Name'
                  rules={[
                    {
                      required: true,
                      message: "Please enter your first name.",
                    },
                    {
                      type: "string",
                      max: 50,
                      message: "Your first name must be 50 characters or less.",
                    },
                  ]}
                >
                  <Input size='large' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='lastName'
                  data-testid='lastName'
                  label='Last Name'
                  rules={[
                    {
                      required: true,
                      message: "Please enter your last name.",
                    },
                    {
                      type: "string",
                      max: 50,
                      message: "Your last name must be 50 characters or less.",
                    },
                  ]}
                >
                  <Input size='large' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
              <Col span={12}>
                {" "}
                <Form.Item
                  name='email'
                  data-testid='email'
                  label='Email'
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please enter a valid email address.",
                    },
                  ]}
                >
                  <Input size='large' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <PhoneNumber />
              </Col>
            </Row>
            <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
              <Col span={12}>
                {" "}
                <Form.Item
                  name='company'
                  data-testid='company'
                  label='Your Company'
                >
                  <Input size='large' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Your Service'
                  name='service'
                  data-testid='service'
                  className='profile-settings-service'
                >
                  <Select
                    defaultValue=''
                    size='large'
                    options={[
                      { value: "", label: "Select..." },
                      { value: "hvac", label: "HVAC" },
                      { value: "lighting", label: "Lighting" },
                      { value: "refrigeration", label: "Refrigeration" },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <SubmitButton
              className='profile-settings-button'
              testId='profile-settings-button'
              form={form}
              overrideDisabled={false}
            >
              Update Profile
            </SubmitButton>
          </Form>
        </Col>
      </Row>
    </>
  );
};
