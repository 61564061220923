import { useCallback, useState } from "react";

import { projectService } from "../services";
import { IProject } from "projects/models";
import { isEmpty } from "lodash";

export const useGetProjects = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<IProject[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any | null>(null);

  const getProjects = useCallback(
    async (
      projectId?: string,
      status?: string,
      subcontractor?: string,
      utilityProgram?: string,
      salesRep?: string,
      customerPaymentMethod?: string,
      stage?: string,
      showArchived?: boolean,
      getMore?: boolean,
      lastKey?: any,
      projects: IProject[] = []
    ) => {
      try {
        if (loading || (!getMore && !isEmpty(lastKey))) return;

        setLoading(true);
        setSuccess(false);
        setError(undefined);
        setLastEvaluatedKey(null);
        setData([]);

        const result = await projectService.getProjects(
          projectId,
          status,
          subcontractor,
          utilityProgram,
          salesRep,
          customerPaymentMethod,
          stage,
          showArchived,
          lastKey
        );

        setData([...(isEmpty(lastKey) ? [] : projects), ...result.items]);
        setLastEvaluatedKey(result.lastEvaluatedKey || null);
        setHasMore(Boolean(result.lastEvaluatedKey));
        setSuccess(true);
      } catch (error) {
        setSuccess(false);
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    getProjects,
    loading,
    success,
    hasMore,
    error,
    data,
    lastEvaluatedKey,
  };
};
