import { useState } from "react";
import { isEmpty } from "lodash";

import { useBoundStore } from "store";
import { userService } from "users/services";
import { IUserConfiguration } from "users/models/IUserConfiguration";

export const useUpdateUserConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const user = useBoundStore((state) => state.user);
  const setUser = useBoundStore((state) => state.setUser);

  const updateUserConfiguration = async (config: IUserConfiguration) => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);

      if (isEmpty(user?.id)) throw new Error("");

      const updatedUser = await userService.updateUserConfiguration(
        user?.id as string,
        config
      );

      setUser(updatedUser);

      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    updateUserConfiguration,
    loading,
    success,
    error,
  };
};
