import { StateCreator } from "zustand";

import { ISelectOption } from "shared/models";
import { IAuthSlice } from "../models";
import { sliceResetFns } from "store";

const initialState = {
  salesRepsSelectOptions: [],
  token: "",
};

export const createAuthSlice: StateCreator<
  IAuthSlice,
  [["zustand/devtools", never]],
  [],
  IAuthSlice
> = (set) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setSalesRepsSelectOptions: (selectOptions: ISelectOption[]) =>
      set(() => ({
        salesRepsSelectOptions: selectOptions,
      })),
    setToken: (token) =>
      set(() => ({
        token,
      })),
    resetAuthSlice: () => set(() => initialState),
  };
};
