import { INote } from "notes/models";
import { notesService } from "notes/services";
import { useState } from "react";

export const useGetNotes = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<INote[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [lastKey, setLastKey] = useState<any | null>(null);

  const getNotes = async (projectId?: string) => {
    try {
      if (loading || !hasMore) return; // Avoid duplicate requests

      setLoading(true);
      setSuccess(false);
      setError(undefined);
      setData([]);

      const { items, lastEvaluatedKey } = await notesService.getNotes(
        projectId,
        lastKey
      );

      setData([...data, ...items]);
      setLastKey(lastEvaluatedKey || null);
      setHasMore(Boolean(lastEvaluatedKey));
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getNotes,
    loading,
    success,
    hasMore,
    error,
    data,
  };
};
