import { httpService } from "shared/services";
import { INote } from "notes/models";
import { PaginatedResult } from "shared/models";

const apiPath = `${process.env.REACT_APP_API_BASE_URL}/api/v1/notes`;

export const getNotes = async (projectId?: string, lastEvaluatedKey?: any) => {
  const params = {
    projectId,
    lastEvaluatedKey,
  };
  const res = await httpService.instance.post<PaginatedResult<INote>>(
    `${apiPath}/all`,
    params
  );
  return res?.data;
};
