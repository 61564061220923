import { useState } from "react";

import { isEmpty } from "lodash";
import { useBoundStore } from "store";
import { RcFile } from "antd/es/upload";
import { userService } from "users/services";

export const useUpdateProfilePicture = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const user = useBoundStore((state) => state.user);
  const setUser = useBoundStore((state) => state.setUser);

  const updateProfilePicture = async (file: RcFile) => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);

      if (isEmpty(user?.id)) throw new Error("");

      const profilePicture = await userService.updateProfilePicture(
        user?.id as string,
        file
      );

      setUser({
        ...user,
        profilePicture: profilePicture,
      });

      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    updateProfilePicture,
    loading,
    success,
    error,
  };
};
