import {
  GetProp,
  Upload,
  UploadFile,
  UploadProps,
  Image,
  notification,
  message,
} from "antd";
import "./ProfilePictureUpload.scss";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useBoundStore } from "store";
import { useUpdateProfilePicture } from "users/hooks";
import { RcFile } from "antd/es/upload";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const ProfilePictureUpload = () => {
  const user = useBoundStore((state) => state.user);
  const { updateProfilePicture, success, error } = useUpdateProfilePicture();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(user?.profilePicture);
  const [fileList, setFileList] = useState<UploadFile[]>(
    user?.profilePicture
      ? [
          {
            uid: "-1",
            name: "profile.png",
            status: "done",
            url: user?.profilePicture,
          },
        ]
      : []
  );
  const [api, contextHolder] = notification.useNotification();

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload your photo</div>
    </button>
  );

  const customRequestHandler = async (options: any) => {
    const { onSuccess, onError, file } = options;

    try {
      await updateProfilePicture(file);
      onSuccess("Ok");
    } catch (err) {
      onError(err);
    }
  };

  const validateUploadHandler = async (file: RcFile) => {
    const validImageTypes = ["image/png", "image/jpeg"];
    const isValidImageType = validImageTypes.includes(file.type);
    const isLessThan1MB = file.size / 1024 / 1024 < 1;

    if (!isValidImageType) {
      message.error(
        `${file.name} is not a valid image. The following image types are accepted: ["image/png", "image/jpeg"].`,
        3
      );
    }

    if (!isLessThan1MB) {
      message.error(
        `${file.name} is not a valid image. The image must be smaller than 1MB.`,
        3
      );
    }

    return true;
  };

  useEffect(() => {
    if (success) {
      api.success({
        duration: 3,
        message: "Update profile picture complete",
        description: "Your profile picture has been updated.",
      });
    } else if (error) {
      api.error({
        message: "Update profile picture error",
        description: error?.message,
      });
    }
  }, [success, error, api]);

  return (
    <>
      {contextHolder}

      <Upload
        listType='picture-card'
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        className='profile-picture-upload'
        customRequest={customRequestHandler}
        accept='image/png, image/jpeg'
        beforeUpload={validateUploadHandler}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{ display: "none" }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </>
  );
};
