import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { RcFile } from "antd/es/upload";

import { ISelectOption } from "shared/models";
import { httpService } from "shared/services";
import { authService } from "auth/services";
import { ISimpleUser, IUserRoleSelectOption } from "../models";
import { IUserConfiguration } from "users/models/IUserConfiguration";

const apiPath = `${process.env.REACT_APP_API_BASE_URL}/api/v1/users`;

export const getSimpleUser = async (id: string) => {
  const res = await httpService.instance.get<ISimpleUser>(
    `${apiPath}/${id}/simple`
  );
  return res?.data;
};

export const getSalesReps = async () => {
  const res = await httpService.instance.get<ISelectOption[]>(
    `${apiPath}/salesreps`
  );
  return res?.data;
};

export const updateUser = async (id: string, user: ISimpleUser) => {
  const userAttributeList = [
    new CognitoUserAttribute({
      Name: "email",
      Value: user?.email?.trim() as string,
    }),
    new CognitoUserAttribute({
      Name: "name",
      Value: `${user?.firstName} ${user?.lastName}`.trim(),
    }),
    new CognitoUserAttribute({
      Name: "given_name",
      Value: user?.firstName?.trim() as string,
    }),
    new CognitoUserAttribute({
      Name: "family_name",
      Value: user?.lastName?.trim() as string,
    }),
    new CognitoUserAttribute({
      Name: "phone_number",
      Value: user?.phoneNumber?.trim() as string,
    }),
  ];

  try {
    await authService.updateAttributes(userAttributeList);

    const res = await httpService.instance.put<ISimpleUser>(
      `${apiPath}/${id}`,
      user
    );
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addUser = async (user: ISimpleUser) => {
  const res = await httpService.instance.post<ISimpleUser>(`${apiPath}`, user);
  return res?.data;
};

export const updateProfilePicture = async (
  id: string,
  file: RcFile
): Promise<string | undefined> => {
  const formData = new FormData();
  formData.append("profile_picture", file);

  const res = await httpService.instance.post(
    `${apiPath}/${id}/updateProfilePicture`,
    formData
  );

  return res?.data;
};

export const updateUserConfiguration = async (
  id: string,
  config: IUserConfiguration
) => {
  try {
    const res = await httpService.instance.put<ISimpleUser>(
      `${apiPath}/${id}/configuration`,
      config
    );
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUserRole = async (id: string, role: string) => {
  try {
    const res = await httpService.instance.put<ISimpleUser>(
      `${apiPath}/${id}/role`,
      {
        id,
        role,
      }
    );
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteUser = async (id: string) => {
  try {
    const res = await httpService.instance.delete<string>(`${apiPath}/${id}`);
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserSelectOptions = async () => {
  const res = await httpService.instance.get<IUserRoleSelectOption[]>(
    `${apiPath}/select`
  );
  return res?.data;
};

export const getUserMentionOptions = async () => {
  const res = await httpService.instance.get<ISelectOption[]>(
    `${apiPath}/mention`
  );
  return res?.data;
};
