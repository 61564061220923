import {
  Avatar,
  Badge,
  Col,
  List,
  notification,
  Row,
  Skeleton,
  Spin,
  Tag,
  Typography,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import VirtualList, { ListRef } from "rc-virtual-list";

import { useGetNotes } from "notes/hooks";
import "./NotesList.scss";
import React from "react";
import { DateTimeFormat } from "shared/constants";
import { Link } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(relativeTime);

const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
  event.preventDefault();
  event.stopPropagation();
};

type Props = {
  title: string;
  height?: number;
  projectId?: string;
};

export const NotesList = ({ title, projectId, height = 1050 }: Props) => {
  const { getNotes, error, success, data, hasMore, loading } = useGetNotes();
  const [api, notificationContextHolder] = notification.useNotification();
  const [scrollTop, setScrollTop] = useState<number>(0);
  const listRef = React.useRef<ListRef>(null);
  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    getNotes(projectId);
  }, []);

  useEffect(() => {
    listRef?.current?.scrollTo({
      index: scrollTop,
      align: "top",
      offset: 15,
    });
  }, [data]);

  useEffect(() => {
    if (success === true) {
      setHasInitialized(true);
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <>
      {success ? (
        <div>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
            justify={"space-between"}
          >
            <Col>
              <Typography.Title className='notes-title' level={3}>
                {title}
              </Typography.Title>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
            justify={"space-between"}
          >
            <Col span={24}>
              <div className='notes-container'>
                {notificationContextHolder}
                <List itemLayout='horizontal'>
                  <VirtualList
                    ref={listRef}
                    data={data}
                    className='notes-list-container'
                    height={height}
                    itemKey='id'
                    onScroll={(e: React.UIEvent<HTMLElement, UIEvent>) => {
                      if (
                        e.currentTarget.scrollHeight -
                          e.currentTarget.scrollTop <=
                          e.currentTarget.clientHeight + 25 &&
                        hasMore &&
                        !loading
                      ) {
                        setScrollTop(e.currentTarget.scrollHeight);
                        getNotes(projectId);
                      }
                    }}
                  >
                    {(note) => (
                      <List.Item className='notes-list-item'>
                        <List.Item.Meta
                          avatar={
                            <Badge
                              dot
                              status='success'
                              size='default'
                              offset={[-6, 35]}
                            >
                              <Avatar
                                shape='circle'
                                size='large'
                                src={note?.createdByProfilePicture}
                                icon={<UserOutlined />}
                              />
                            </Badge>
                          }
                          title={
                            <>
                              <Row>
                                <Col className='notes-note-tag'>
                                  {note?.projectName && (
                                    <Tag
                                      color='#D9D9D9'
                                      onMouseDown={onPreventMouseDown}
                                    >
                                      <Link
                                        className='notes-note-tag-link'
                                        to={`/projects?id=${note?.projectId}`}
                                      >
                                        {note?.projectName}
                                      </Link>
                                    </Tag>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col span={14}>
                                  <Typography.Title
                                    level={2}
                                    className='notes-note-title'
                                  >
                                    {note?.createdByName}
                                  </Typography.Title>
                                </Col>
                                <Col span={10}>
                                  <Typography.Title
                                    level={3}
                                    className='notes-note-time'
                                  >
                                    {dayjs(note?.createdDate).format(
                                      DateTimeFormat
                                    )}
                                  </Typography.Title>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24} className='notes-note-content'>
                                  {note?.content}
                                </Col>
                              </Row>
                            </>
                          }
                        />
                      </List.Item>
                    )}
                  </VirtualList>
                </List>
              </div>
            </Col>
          </Row>
        </div>
      ) : hasInitialized ? (
        <Spin size='large' className='note-spin' />
      ) : (
        <Skeleton />
      )}
    </>
  );
};
