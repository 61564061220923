import { Col, notification, Row, Typography, Card, Skeleton } from "antd";
import { useEffect } from "react";

import "./FinanceManagerDashboardNotesCard.scss";

import { ProjectNotes } from "projects/components";
import { useGetNotes } from "notes/hooks";

export const FinanceManagerDashboardNotesCard = () => {
  const { getNotes, error, success, data } = useGetNotes();
  const [api, notificationContextHolder] = notification.useNotification();

  useEffect(() => {
    getNotes();
  }, []);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <Card className='finance-manager-dashboard-notes-card-container'>
      {notificationContextHolder}
      {success ? (
        <div>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
            justify={"space-between"}
          >
            <Col>
              <Typography.Title
                className='finance-manager-dashboard-notes-card-title'
                level={3}
              >
                My Project Notes
              </Typography.Title>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
            justify={"space-between"}
          >
            <Col span={24}>
              <ProjectNotes notes={data ?? []} />
            </Col>
          </Row>
        </div>
      ) : (
        <Skeleton />
      )}
    </Card>
  );
};
