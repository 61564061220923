import {
  Form,
  Select,
  Typography,
  Image,
  Space,
  Col,
  Row,
  Skeleton,
  Button,
  notification,
} from "antd";
import selectProjectStage from "../../assets/images/select-project-stage.svg";
import "./UpdateUserRole.scss";
import { useEffect, useState } from "react";
import {
  useDeleteUser,
  useGetUserSelectOptions,
  useUpdateUserRole,
} from "admin/hooks";
import { UserRoleSelectOptions } from "admin/constants";
import { SubmitButton } from "shared/components";

export const UpdateUserRole = () => {
  const useGetUserSelectOptionsHook = useGetUserSelectOptions();
  const useUpdateUserRoleHook = useUpdateUserRole();
  const useDeleteUserHook = useDeleteUser();
  const userRoleSelectOptions = UserRoleSelectOptions();
  const [form] = Form.useForm();
  const [selectedRole, setSelectedRole] = useState("");
  const [api, notificationContextHolder] = notification.useNotification();

  const onFinishHandler = async (values: any) => {
    const user = values?.user?.trim();
    const role = values?.role?.trim();

    await useUpdateUserRoleHook.updateUserRole(user, role);

    setSelectedRole("");
    form.resetFields();
  };

  const onDeleteHandler = async () => {
    const userId = form?.getFieldValue("user").trim();

    await useDeleteUserHook.deleteUser(userId);

    setSelectedRole("");
    form.resetFields();
  };

  useEffect(() => {
    const run = async () => {
      await useGetUserSelectOptionsHook?.getUserSelectOptions();
    };

    run();
  }, []);

  useEffect(() => {
    if (useUpdateUserRoleHook.success) {
      api.success({
        duration: 3,
        message: "User Saved",
        description: "The user has been saved.",
      });
      useGetUserSelectOptionsHook?.getUserSelectOptions();
    }

    if (useUpdateUserRoleHook.error) {
      api.error({
        message: "Error",
        description: useUpdateUserRoleHook.error?.message,
      });
    }
  }, [useUpdateUserRoleHook.success, useUpdateUserRoleHook.error, api]);

  useEffect(() => {
    if (useDeleteUserHook.success) {
      api.success({
        duration: 3,
        message: "User Deleted",
        description: "The user has been deleted.",
      });
      useGetUserSelectOptionsHook?.getUserSelectOptions();
    }

    if (useDeleteUserHook.error) {
      api.error({
        message: "Error",
        description: useDeleteUserHook.error?.message,
      });
    }
  }, [useDeleteUserHook.success, useDeleteUserHook.error, api]);

  return (
    <>
      {notificationContextHolder}
      {useGetUserSelectOptionsHook?.success ? (
        <>
          <Form
            form={form}
            name='update-user-role-form'
            data-testid='update-user-role-form'
            layout='vertical'
            onFinish={onFinishHandler}
            requiredMark={false}
          >
            <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
              <Col span={8}>
                <Form.Item
                  name='user'
                  data-testid='user'
                  className='update-user-role-select-box'
                  labelAlign='left'
                  label={
                    <>
                      <Space>
                        <Image
                          src={selectProjectStage}
                          alt='Select User'
                          preview={false}
                          width={22}
                        />
                        <Typography.Text className='update-user-role-title-small'>
                          Select User
                        </Typography.Text>
                      </Space>
                    </>
                  }
                  required
                >
                  <Select
                    defaultValue={""}
                    size='large'
                    options={[
                      { value: "", label: "Select..." },
                      ...(useGetUserSelectOptionsHook?.data ?? []),
                    ]}
                    onSelect={async (value) => {
                      const user = useGetUserSelectOptionsHook?.data?.find(
                        (u) => u?.value === value
                      );
                      const role = userRoleSelectOptions.find(
                        (r) => r?.value === user?.role
                      )?.value;

                      if (role) {
                        form.setFieldsValue({
                          user: value,
                          role: role,
                        });
                        setSelectedRole(role as string);
                        return;
                      }

                      await form.setFieldValue("user", value);
                    }}
                  />{" "}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='role'
                  data-testid='role'
                  className='update-user-role-select-box'
                  labelAlign='left'
                  label={
                    <>
                      <Space>
                        <Image
                          src={selectProjectStage}
                          alt='Edit User Role'
                          preview={false}
                          width={22}
                        />
                        <Typography.Text className='update-user-role-title-small'>
                          Edit User Role
                        </Typography.Text>
                      </Space>
                    </>
                  }
                >
                  <Select
                    key={selectedRole}
                    defaultValue={selectedRole ?? ""}
                    size='large'
                    options={[
                      { value: "", label: "Select..." },
                      ...userRoleSelectOptions,
                    ]}
                    onSelect={(value) => {
                      form.setFieldValue("role", value);
                    }}
                  />{" "}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
              <Col span={4}>
                <SubmitButton
                  className='update-user-role-form-submit-button'
                  testId='update-user-role-form-submit-button'
                  form={form}
                >
                  Save User
                </SubmitButton>
              </Col>
              <Col span={4}>
                <Button
                  danger
                  type='primary'
                  size='large'
                  block
                  className='delete-user-button'
                  onClick={onDeleteHandler}
                >
                  Delete User
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        <Skeleton />
      )}
    </>
  );
};
