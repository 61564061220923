import { sortBy } from "lodash";
import { ISelectOption } from "shared/models";

export const ProjectStages: ISelectOption[] = sortBy(
  [
    {
      label: "Prospecting / Leads",
      value: "prospecting_leads",
    },
    {
      label: "Ready for Audit",
      value: "ready_for_audit",
    },
    {
      label: "Active Auditing",
      value: "active_auditing",
    },
    {
      label: "Engineering Prep",
      value: "engineering_prep",
    },
    {
      label: "Engineering / Proposal Development",
      value: "engineering_proposal_development",
    },
    {
      label: "Presenting",
      value: "presenting",
    },
    {
      label: "Sold / Utility Upload",
      value: "sold_utility_upload",
    },
    {
      label: "Installation",
      value: "installation",
    },
    {
      label: "Closeout",
      value: "closeout",
    },
    {
      label: "Billing",
      value: "billing",
    },
    {
      label: "Completed",
      value: "completed",
    },
    {
      label: "Delayed Opportunities",
      value: "delayed_opportunities",
    },
    {
      label: "Lost / Dead Opportunities",
      value: "lost_dead_opportunities",
    },
  ],
  (o) => o.label
);
