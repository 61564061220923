import { Button, Divider, Flex, Form, Typography, Image, Switch } from "antd";
import { mapValues, unionBy } from "lodash";

import filterProjectsIcon from "../../assets/images/filter-projects.svg";
import paymentMethodIcon from "../../assets/images/payment-method.svg";
import projectStatusIcon from "../../assets/images/project-status.svg";
import salesPersonIcon from "../../assets/images/sales-person.svg";
import subcontractorIcon from "../../assets/images/subcontractor.svg";
import utilityProgramIcon from "../../assets/images/utility-program.svg";
import { MultiSelectBox } from "shared/components";
import {
  CustomerPaymentMethods,
  ProjectStatuses,
  UtilityPrograms,
} from "projects/constants";
import { IProjectFilter } from "projects/models";
import { useBoundStore } from "store";
import "./ProjectFilters.scss";

export const ProjectFilters = () => {
  const [form] = Form.useForm();
  const {
    setFilters,
    clearFilters,
    hvacSubcontractorsSelectOptions,
    lightingSubcontractorsSelectOptions,
    refrigerationSubcontractorsSelectOptions,
    salesRepsSelectOptions,
    setShowArchived,
    filters,
    showArchived,
  } = useBoundStore();

  const clearFiltersHandler = () => {
    form.setFieldsValue({
      projectStatus: [],
      subcontractor: [],
      utilityProgram: [],
      salesRep: [],
      customerPaymentMethod: [],
    });
    clearFilters();
  };

  const onValuesChangeHandler = async (
    changedValues: any,
    allValues: IProjectFilter
  ) => {
    const filters = mapValues(allValues, (x) => x ?? []);
    setFilters(filters);
  };

  const onShowArchivedSwitchChangeHandler = (checked: boolean) => {
    setShowArchived(checked);
  };

  return (
    <>
      <Flex gap='0 28px'>
        <Flex gap='0 4px'>
          <Image
            src={filterProjectsIcon}
            alt='Filter Projects'
            preview={false}
          />
          <Typography.Title className='project-list-title' level={2}>
            Filter Projects
          </Typography.Title>
        </Flex>
        <Button
          ghost
          type='primary'
          className='project-list-clear-filters-button'
          size='small'
          onClick={clearFiltersHandler}
        >
          Clear all filters
        </Button>
        <Flex gap='0 8px' className='project-list-show-archived-switch'>
          <Typography.Text>Show Archived</Typography.Text>
          <Switch
            defaultValue={showArchived}
            onChange={onShowArchivedSwitchChangeHandler}
          />
        </Flex>
      </Flex>
      <Divider />
      <Form
        form={form}
        name='projectFiltersForm'
        layout='vertical'
        initialValues={{
          projectStatus: [...filters?.projectStatus],
          subcontractor: [...filters?.subcontractor],
          utilityProgram: [...filters?.utilityProgram],
          salesRep: [...filters?.salesRep],
          customerPaymentMethod: [...filters?.customerPaymentMethod],
        }}
        onValuesChange={onValuesChangeHandler}
      >
        <Flex justify='space-between'>
          <Flex gap='0 4px'>
            <Image
              src={projectStatusIcon}
              alt='Project Status'
              preview={false}
            />
            <MultiSelectBox
              options={ProjectStatuses}
              name='projectStatus'
              label='Project Status'
            />
          </Flex>
          <Flex gap='0 4px'>
            <Image
              src={subcontractorIcon}
              alt='Subcontractor'
              preview={false}
            />
            <MultiSelectBox
              options={unionBy(
                [
                  ...hvacSubcontractorsSelectOptions,
                  ...lightingSubcontractorsSelectOptions,
                  ...refrigerationSubcontractorsSelectOptions,
                ],
                "value"
              )}
              name='subcontractor'
              label='Subcontractor'
            />
          </Flex>
          <Flex gap='0 4px'>
            <Image
              src={utilityProgramIcon}
              alt='Utility Program'
              preview={false}
            />
            <MultiSelectBox
              options={UtilityPrograms}
              name='utilityProgram'
              label='Utility Program'
            />
          </Flex>
          <Flex gap='0 4px'>
            <Image src={salesPersonIcon} alt='Sales Rep' preview={false} />
            <MultiSelectBox
              options={salesRepsSelectOptions}
              name='salesRep'
              label='Sales Rep'
            />
          </Flex>
          <Flex gap='0 4px'>
            <Image
              src={paymentMethodIcon}
              alt='Customer Payment Method'
              preview={false}
            />
            <MultiSelectBox
              options={CustomerPaymentMethods}
              name='customerPaymentMethod'
              label='Customer Payment Method'
            />
          </Flex>
        </Flex>
      </Form>
    </>
  );
};
