import { resetAllSlices, useBoundStore } from "store";
import { authService } from "auth/services";
import axios from "axios";
import { router } from "index";

export const instance = axios;

instance.interceptors.request.use(
  function (config) {
    const token = useBoundStore.getState().token;
    config.headers.Authorization = token;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error?.response?.data);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error?.response?.status === 401) {
      try {
        await authService.logout();
      } catch (err) {
        return Promise.reject(error?.response?.data);
      }

      resetAllSlices();
      router.navigate("/login");
      return;
    }

    if (error?.response?.status === 403) {
      router.navigate("/forbidden");
      return;
    }

    return Promise.reject(error?.response?.data);
  }
);
