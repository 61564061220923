import { useState } from "react";

import { userService } from "users/services";

export const useUpdateUserRole = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const updateUserRole = async (userId: string, role: string) => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);

      const user = await userService.updateUserRole(userId, role);

      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    updateUserRole,
    loading,
    success,
    error,
  };
};
