import { useState } from "react";
import { ISalesInfo } from "sales/models";

import { salesService } from "sales/services";
import { useBoundStore } from "store";

export const useGetSalesInfo = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState<ISalesInfo | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const {
    setTargetVsActualSales,
    setSalesCards,
    setSalesByUtility,
    setSalesLeaderboard,
  } = useBoundStore();

  const getSalesInfo = async () => {
    try {
      setLoading(true);
      setSuccess(false);
      setData(undefined);
      setError(undefined);

      const salesInfo = await salesService.getSalesInfo();

      setTargetVsActualSales(salesInfo.targetVsActualSales);
      setSalesCards(salesInfo.salesCards);
      setSalesByUtility(salesInfo.salesByUtility);
      setSalesLeaderboard(salesInfo.salesLeaderboard);
      setData(salesInfo);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getSalesInfo,
    loading,
    success,
    data,
    error,
  };
};
