import { Avatar, Badge, Col, Flex, List, Row, Tag, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

import dayjs from "dayjs";
import VirtualList from "rc-virtual-list";

import "./ProjectNotes.scss";
import { INote } from "notes/models";
import { DateTimeFormat } from "shared/constants";

dayjs.extend(utc);
dayjs.extend(relativeTime);

type Props = {
  notes: INote[];
};

const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
  event.preventDefault();
  event.stopPropagation();
};

export const ProjectNotes = ({ notes }: Props) => {
  return (
    <div className='project-notes-container'>
      <List itemLayout='horizontal'>
        <VirtualList
          data={notes}
          className='project-notes-list-container'
          itemHeight={47}
          itemKey='id'
        >
          {(note) => (
            <List.Item className='project-notes-list-item'>
              <List.Item.Meta
                avatar={
                  <Badge dot status='success' size='default' offset={[-6, 35]}>
                    <Avatar
                      shape='circle'
                      size='large'
                      src={note?.createdByProfilePicture}
                      icon={<UserOutlined />}
                    />
                  </Badge>
                }
                title={
                  <>
                    <Row>
                      <Col span={14}>
                        <Typography.Title
                          level={2}
                          className='project-notes-note-title'
                        >
                          {note?.createdByName}
                        </Typography.Title>
                      </Col>
                      <Col span={10}>
                        <Typography.Title
                          level={3}
                          className='project-notes-note-time'
                        >
                          {dayjs(note?.createdDate).format(DateTimeFormat)}
                        </Typography.Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={14} className='project-notes-note-content'>
                        {note?.content}
                      </Col>
                      <Col span={10} className='project-notes-note-tag'>
                        {note?.projectName && (
                          <Tag color='#D9D9D9' onMouseDown={onPreventMouseDown}>
                            {note?.projectName}
                          </Tag>
                        )}
                      </Col>
                    </Row>
                  </>
                }
              />
            </List.Item>
          )}
        </VirtualList>
      </List>
    </div>
  );
};
