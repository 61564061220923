import {
  Col,
  notification,
  Row,
  Typography,
  Image,
  Card,
  Skeleton,
} from "antd";
import { useEffect } from "react";

import { useGetSalesInfo } from "sales/hooks";

import "./OwnerDashboardSalesCard.scss";
import { SalesTargets, SalesTargetTimePeriods } from "users/components";
import actualSalesIcon from "sales/assets/images/actual_sales.svg";
import targetSalesIcon from "sales/assets/images/target_sales.svg";
import newSalesIcon from "sales/assets/images/new_sales.svg";
import {
  SalesByUtility,
  SalesLeaderboard,
  TargetVsActualSales,
} from "sales/components";
import { utilityService } from "shared/services";
import { StatisticCard } from "shared/components";
import { useBoundStore } from "store";

export const OwnerDashboardSalesCard = () => {
  const { getSalesInfo, success, error, loading } = useGetSalesInfo();
  const [api, notificationContextHolder] = notification.useNotification();
  const { salesCards } = useBoundStore();

  const onSalesTargetTimePeriodChange = () => {
    getSalesInfo();
  };

  useEffect(() => {
    getSalesInfo();
  }, []);

  useEffect(() => {
    if (error) {
      api.error({
        message: "Error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <Card className='owner-dashboard-sales-card-container'>
      {notificationContextHolder}
      {success ? (
        <div>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
            justify={"space-between"}
          >
            <Col>
              <Typography.Title
                className='owner-dashboard-sales-card-title'
                level={3}
              >
                Sales
              </Typography.Title>
            </Col>
            <Col>
              <SalesTargetTimePeriods
                name='sales-target-time-periods'
                onChange={onSalesTargetTimePeriodChange}
              />
            </Col>
          </Row>
          <SalesTargets name='sales-targets' />
          <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
            <Col span={24}>
              <TargetVsActualSales />
            </Col>
          </Row>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
            justify={"space-between"}
          >
            <Col span={8}>
              <StatisticCard
                title='Target Sales'
                value={salesCards?.targetSales ?? 0}
                prefix='$'
                formatter={utilityService.statisticsFormatter}
                backgroundColor='#FFF4DE'
                Icon={() => (
                  <Image
                    src={targetSalesIcon}
                    alt='Target Sales Icon'
                    preview={false}
                    width={35}
                  />
                )}
              />
            </Col>
            <Col span={8}>
              <StatisticCard
                title='Actual Sales'
                value={salesCards?.actualSales ?? 0}
                prefix='$'
                formatter={utilityService.statisticsFormatter}
                backgroundColor='#E2FFF3'
                Icon={() => (
                  <Image
                    src={actualSalesIcon}
                    alt='Actual Sales Icon'
                    preview={false}
                    width={35}
                  />
                )}
              />
            </Col>
            <Col span={8}>
              <StatisticCard
                title='New Sales'
                value={salesCards?.newSales ?? 0}
                precision={0}
                backgroundColor='#EBF3FE'
                Icon={() => (
                  <Image
                    src={newSalesIcon}
                    alt='New Sales Icon'
                    preview={false}
                    width={35}
                  />
                )}
              />
            </Col>
          </Row>
          <Row
            gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
            justify={"space-between"}
          >
            <Col span={14}>
              <SalesLeaderboard dataLoading={loading} />
            </Col>
            <Col span={8}>
              <SalesByUtility dataLoading={loading} />
            </Col>
          </Row>
        </div>
      ) : (
        <Skeleton />
      )}
    </Card>
  );
};
