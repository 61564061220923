import { useState } from "react";
import { isEmpty } from "lodash";
import { createSearchParams, useNavigate } from "react-router-dom";

import { VerificationTypes } from "auth/constants";
import { useBoundStore } from "store";
import { ISimpleUser } from "users/models";
import { userService } from "users/services";

export const useUpdateProfileSettings = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const navigate = useNavigate();
  const user = useBoundStore((state) => state.user);
  const setUser = useBoundStore((state) => state.setUser);

  const updateProfileSettings = async (updatedUser: ISimpleUser) => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);

      if (!isEmpty(user?.id)) {
        const currentUser = await userService.updateUser(
          user?.id as string,
          updatedUser
        );

        setUser(currentUser);

        if (user?.email !== updatedUser.email) {
          navigate({
            pathname: "/verify",
            search: createSearchParams({
              email: user.email ?? "",
              type: VerificationTypes.EMAIL,
            }).toString(),
          });
        }
      }

      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    updateProfileSettings,
    loading,
    success,
    error,
  };
};
